
import * as React from 'react';

import Typography from '@mui/material/Typography';
import { Carousel } from '@trendyol-js/react-carousel';
import LeftArrow from '@mui/icons-material/ArrowBackIos';
import RightArrow from '@mui/icons-material/ArrowForwardIos';
import '../../Corps.css';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
function Projetinf() {
  const [open, setOpen] = React.useState("null");
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType, param) => () => {
    setOpen(param);
    setScroll(scrollType);
    document.getElementById("contentdialog").style.display = "block";
  };

  const handleClose = () => {
    setOpen("null");
    document.getElementById("contentdialog").style.display = "none";

  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const projets = [

    {
      src: require('../../Images/projet/API.png'),
      titre: 'API - PHP',
      content: "API des applications mobile et bureau de A regarder ",
      srcModal: require('../../videos/A_regarder_mob.mp4'),
      Description: [
        { obj1: " API créée en php. Sert pour l'application de bureau et l'application mobile 'A regarder'. Pour plus de détail regarder la vidéo ci-dessus." },],
    },
    {
      src: require('../../Images/projet/casseBrique.png'),
      titre: 'Casse Brique - Javascript',
      content: "Jeu Casse Brique - Javascript",
      srcModal: require('../../videos/casseBrique.mp4'),
      Description: [{ obj1: "https://fannygoupil.fr/Casse_Brique_fg/casse_brique.html " }, { obj1: "But du jeu: Casser toutes les briques avec la balle. Le joueur possède pour cela 3 vies. Le nombre de brique peut être choisi par l'utilisateur." }],



    },
    {
      src: require('../../Images/projet/calculatrice.png'),
      titre: 'Calculatrice - HTML, CSS, JS',
      content: "Calculatrice qui faits de simples calculs",
      srcModal: require('../../videos/calculatrice.mp4'),
      Description: [{ obj1: "https://fannygoupil.fr/Calculatrice " }, { obj1: "Calculatrice qui fait de simples calculs tel que le modulo, la division, la soustraction et la division.Possibilité de changer de thèmes. Utilisation des variables CSS." }],
    },
    {
      src: require('../../Images/projet/shi_fu_mi.png'),
      titre: 'Pierre Papier Ciseaux - HTML, CSS, JS',
      content: "Pierre Papier Ciseaux  ",
      srcModal: require('../../videos/shi_fu_mi.mp4'),
      Description: [
        { obj1: "https://fannygoupil.fr/Shi_Fu_Mi/" },
        { obj1: " Creation d'un jeu Pierre Papier Ciseaux en JS avec fonction random()" },],

    },
    {
      src: require('../../Images/projet/snake.jpg'),
      titre: 'Snake - Arduino, C++',
      content: "Snake fait en terminal ",
      srcModal: require('../../videos/videoplayback.mp4'),
      Description: [
        { obj1: "https://fannygoupil.fr/pdf/Snake_PDF.pdf" },
        { obj1: " Creation d'un jeu Snake sur une matrice avec des leds. Le joueur dirige un serpent qui grandit au fur et à mesure qu'il mange des fruit." },],

    },
 
    {
      src: require('../../Images/projet/leagueOflegend.png'),
      titre: 'Site Gestion de tournois -PHP JS HTML CSS',
      content: "Site de gestion de tournois de league of legends avec architecture MVC",
      srcModal: require('../../videos/lol.mp4'),
      Description: [{ obj1: "https://fannygoupil.fr/GestionTer/siteProjet/index.php" },
      { obj1: "Site où des equipes peuvent s'inscrire à des tournois, et consulter les tournois en cours. Une partie administrateur et gestionnaire ont été crées pour gerer et creer de nouveaux tournois.  " }],
    },
    {
      src: require('../../Images/projet/aRegarder_mobile.png'),
      titre: 'A regarder -Java Swing',
      content: "Application de bureau ",
      srcModal: require('../../videos/videoplayback.mp4'),
      Description: [
        { obj1: " Application bureau permettant de relater films, séries et animés qui ont étaient vus qui sont entrain d'être vus ou que l'on veut voir. On peut ajouter des films avec un ordre chronologique pour les séres de films. Pour les séries et animés on peut ajouter des saisons, ajouter des épisodes avec ou sans les titres des épisodes. Puis il est possible d'incrémenter ou décrémenter l'épisode à voir ou de passer un film en vu. Informations stockées en base de donnée et l'application intérroge une API." },],
    },
    {
      src: require('../../Images/projet/aRegarder_mobile.png'),
      titre: 'A regarder - Android Studio',
      content: "Adaptation de la version bureau de <A regarder> en version mobile ",
      srcModal: require('../../videos/A_regarder_mob.mp4'),
      Description: [
        { obj1: " Application mobile permettant de relater films, séries et animés qui ont étaient vus qui sont entrain d'être vus ou que l'on veut voir. On peut ajouter des films avec un ordre chronologique pour les séres de films. Pour les séries et animés on peut ajouter des saisons, ajouter des épisodes avec ou sans les titres des épisodes. Puis il est possible d'incrémenter ou décrémenter l'épisode à voir ou de passer un film en vu. Informations stockées en base de donnée et l'application intérroge une API." },],
    },
  ]
  const left = (
    <div className='arrow'>
      <LeftArrow fillColor="#000" />
    </div>
  );
  const right = (
    <div className='arrow'>
      <RightArrow fillColor="#000" />
    </div>);

  return (
    <div><Carousel className='Caroussel' show={3.2} slide={1} swiping={true} rightArrow={right} leftArrow={left} >
      {projets.map((item, index) => <div>
        <Card sx={{ maxWidth: 345, border: "1px dashed red" }}>
          <CardMedia component="img" alt="green iguana" height="150" image={item.src} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div"> {item.titre} </Typography>
            <Typography variant="body2" color="text.secondary">{item.content}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={handleClickOpen('paper', index)}>➕ En Savoir Plus</Button>

          </CardActions>
        </Card></div>)}



    </Carousel>
      <div id="contentdialog"><Dialog
        open={open != "null"}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="modal"
        aria-describedby="scroll-dialog-description"
      >

        <DialogTitle sx={{ color: "#FF9B21" }} id="modal">{open != "null" ? projets[parseInt(open)].titre : ""}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <div>
            <Box sx={{ width: 1 }}>
              <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                <Box gridColumn="span 12">
                  <video controls width="100%" muted poster autoplay>
                    <source src={open != "null" ? (projets[parseInt(open)].srcModal) : 'null'} />
                  </video>
                </Box>
                <Box gridColumn="span 10">
                  <h3 className='titreH3'> Description :</h3>
                  {open != "null" ? (projets[parseInt(open)].Description.map((item, index) => <div>{!item.obj1.includes(".fr") ? item.obj1 : <a href={item.obj1} target="_blank">{item.obj1.includes(".pdf") ? "voir rapport" : "Testez le !!!"}</a>}<br /></div>)) : 'null'}
                </Box>
              </Box>
            </Box>

          </div>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog></div></div>);

}
export default Projetinf