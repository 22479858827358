import './Corps.css';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { borderRadius } from '@mui/system';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images=[
  [
    {src:require('./Images/cerf&fille.jpg')},
    {src:require('./Images/filleBalancoire.jpg')},
    { src:require('./Images/femme2.jpg' )}, 
    {src:require('./Images/garfielsPaysSucre.jpg')}

  ],
  [
    { src:require('./Images/gibliUniverse.jpg')}, 
    {src:require('./Images/kimono.jpg')}, 
    {src:require('./Images/bob_patrick_influenceursrogné.jpg')},
    {src:require('./Images/fille_contours_fins.jpg')},  

  ],
  [
    {src:require('./Images/fille_meditation.jpg')},  
    {src:require('./Images/pandasoleil.jpg')},  
    {src:require('./Images/renard_sous_fondrouge2.jpg')},  
    {src:require('./Images/sorciereHalloween.jpg')},


  ],
  [
    {src:require('./Images/pokemon.jpg') },
    {src:require('./Images/renardesprit(copie).jpg')},
    {src:require('./Images/dragon.jpg') },
    {src:require('./Images/phoenix.jpg') },

  ],
 
     
     
     

  ]

function ProjetGraphique() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className='englobeC'>

    <Box classsName='box'  sx={{ flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 0,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
      </Paper>
      <AutoPlaySwipeableViews className='encadre_img'
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        enableMouseEvents
      >

         {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 4 ? (
              <div className="aggrImg" style={{overflowY:'hidden'}}>
               {step.map((item,index2)=><Box 
                component="img" className='imgC'
                sx={{
                  display: 'block',
                  maxWidth: 350,
                  overflow: 'visible',
                  width: 300,
                  display:'inline-block',
                  marginLeft: '2%',
                }}
                defaultSource={require('./Images/placeholder.png')}
                src={item.src}

              />
              )}
              
              
              </div>
              
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper sx={{background:'#b8b8b8' ,borderRadius:"20px"}}
        steps={maxSteps}
        position="static"
        activeStep ={activeStep}
        nextButton={
          <Button sx={{fontWeight:"bold"}}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{fontWeight:"bold"}}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight  />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box></div>
  );
}

export default ProjetGraphique;
