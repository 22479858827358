import React, { Component } from 'react';
import '../CSS/Presentation.css';
import Box from '@mui/material/Box';
import Apropos from './Apropos';
import Diplome from './Diplomes';
import Experience from './Experience';


class Presentation extends Component {
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }

  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  }
  render() {
    return (

      <div>
        <h1 className='titrePortfolio' >PORTFOLIO</h1>
        <div class="flex-container">
          <div class="flex-item"><Apropos /></div>
          <div class="flex-item" ><Diplome /></div>
          <div style={{marginBottom:'3%'}}class="flex-item"><Experience/></div>



        </div>


      </div>


    )
  }
}

export default Presentation;
