const langPr=[
    {
        src: require("../logos/cc++.png"),
        titre: 'C/C++',

    },
    {
        src: require("../logos/java.png"),
        titre: 'Java',

    },
    {
        src: require("../logos/python.png"),
        titre: 'Python',

    },
    {
      src: require("../logos/scheme.png"),
      titre: 'Scheme',

  },
  {
    src: require("../logos/Coq.png"),
    titre: 'Coq',

},

];
export default langPr