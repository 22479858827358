import React from 'react';
import '../CSS/img.css'
const App = () => {
  return (
    <div className="App">
      <svg className='svg' width="667" height="667" viewBox="0 0 667 667" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="nnnnn 1">
<g id="g4929">
<path id="path3871" d="M235.429 197.233L228.584 179.244L241.491 154.088L261.438 176.903L262.807 177.635L270.629 165.349L297.812 151.455L310.133 181.437L309.937 186.118L305.048 197.379L393.637 224.29L378.774 232.042C378.774 232.042 432.358 259.391 431.771 259.538C431.185 259.684 467.168 300.928 467.168 300.928C467.168 300.928 450.936 308.533 451.132 309.118C451.328 309.703 460.519 360.015 459.737 360.161C458.954 360.308 445.265 358.406 445.265 358.406L418.473 443.966C418.473 443.966 404.002 421.442 403.415 421.735C402.828 422.027 368.801 486.672 368.801 486.672L338.684 485.356L318.542 557.898L291.359 515.923L265.349 523.821C265.349 523.821 233.668 473.509 232.886 472.924C232.104 472.339 213.526 485.356 213.526 485.356L215.286 424.367L240.709 380.637L240.513 343.781L207.268 316.723L134.715 325.645L116.136 315.992L115.159 309.703L96.7758 300.197L98.927 289.52L169.916 259.83L179.889 233.943L231.908 197.087L235.429 197.233Z" fill="#010101" fill-opacity="0.01" stroke="black" stroke-width="0.732286"/>
<path id="path3875" d="M232.088 178.834L242.097 159.11L261.161 178.834L258.46 184.18L232.088 178.834Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3877" d="M275.142 165.883L296.43 154.714L307.551 180.616L275.142 165.883Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3884" d="M272.759 168.616L307.074 182.754L260.844 202.715L260.367 184.418L272.759 168.616Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3886" d="M257.825 185.725L258.143 195.824L238.284 197.487L231.771 181.329L257.825 185.725Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3890" d="M261.002 204.497L306.598 185.487L306.915 188.695L295.318 216.379L277.525 228.973L261.002 204.497Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3892" d="M257.031 198.319C233.359 199.27 218.902 226.834 218.902 226.834L275.301 229.924L257.348 203.903L257.031 198.319Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3906" d="M172.354 259.39L181.091 235.983L200.95 243.468L199.997 244.894L198.726 246.914V248.815L200.632 251.191L203.015 253.568L172.354 259.39Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3912" d="M204.475 244.595L203.14 244.485L201.657 246.148L201.509 248.81L205.216 253.135L220.046 249.364L204.475 244.595Z" fill="#0A020A" stroke="black" stroke-width="0.733055"/>
<path id="path3914" d="M185.344 233.061L215.3 227.072L229.24 201.453L185.344 233.061Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3916" d="M305.613 199.9C306.206 200.011 305.168 203.005 337.645 214.318C370.121 225.63 382.726 225.852 382.726 225.852L375.905 230.177L335.272 230.954L298.791 217.867L303.685 202.562L305.613 199.9Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3920" d="M323.111 205.556C324.001 205.778 389.103 224.521 388.214 224.41C387.324 224.3 375.312 223.634 375.312 223.634L328.45 209.66L323.111 205.556Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3922" d="M101.18 290.994L111.825 286.242L122.31 290.757V292.895L114.208 307.985L99.7505 299.311L101.18 290.994Z" fill="#0A020A" stroke="black" stroke-width="0.733055"/>
<path id="path3924" d="M161.709 265.568L124.693 289.45L115.32 284.935L161.709 265.568Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3926" d="M171.083 261.885L222.238 251.191L210.8 289.687L157.738 303.588L117.862 307.985L126.599 290.757L171.083 261.885Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3928" d="M116.908 310.361L118.497 315.114L134.384 323.074L201.744 315.351L158.214 306.084L116.908 310.361Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3930" d="M161.868 305.014L212.865 291.707L268.31 291.826L206.987 313.925L161.868 305.014Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3932" d="M226.21 251.191L267.198 261.885L274.347 289.212L213.342 289.45L226.21 251.191Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3934" d="M184.745 235.389C186.016 235.627 225.098 248.102 225.098 248.102L271.011 231.706L215.407 229.448L184.745 235.389Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3936" d="M228.593 249.172C229.387 249.409 268.151 259.984 268.151 259.984L330.428 232.894L276.254 232.3L228.593 249.172Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3938" d="M271.17 264.142L318.989 284.935L266.722 314.163L278.001 290.638L271.17 264.142Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3940" d="M213.342 314.995L272.441 293.252L263.862 314.282L213.342 314.995Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3942" d="M272.123 262.004C330.905 289.925 374.435 232.656 374.435 232.656L334.241 232.538L272.123 262.004Z" fill="#FFD021" stroke="black" stroke-width="0.733055"/>
<path id="path3948" d="M292.458 270.915C332.335 276.142 348.221 257.964 348.221 257.964L320.26 283.509L292.458 270.915Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3950" d="M348.221 260.221L378.248 233.369L427.815 260.221L370.94 289.212L348.221 260.221Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3956" d="M211.649 317.027L243.644 343.293L279.006 366.682L285.02 345.632L264.813 316.847L211.649 317.027Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3960" d="M242.922 345.632C243.162 346.172 263.851 359.125 263.851 359.125C263.851 359.125 246.29 397.626 243.403 378.376C240.516 359.125 242.922 345.632 242.922 345.632Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3968" d="M249.658 385.212C265.294 367.941 266.497 360.925 266.497 360.925L278.765 368.841L310.76 433.608L249.658 385.212Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3970" d="M241.96 383.233C245.087 386.472 246.771 385.572 246.771 385.572L307.873 434.507L239.073 419.215L241.96 383.233Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3974" d="M218.592 424.409C250.941 470.422 216.689 478.961 216.689 478.961L218.592 424.409Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3978" d="M220.178 421.563C239.524 452.159 236.352 461.884 236.352 461.884L235.084 419.665L239.206 388.357L220.178 421.563Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3984" d="M239.206 422.274C239.206 422.274 256.332 456.666 261.406 488.211C266.481 519.757 267.749 520.231 267.749 520.231L312.149 436.98L239.206 422.274Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path3986" d="M237.938 426.543C237.938 426.543 246.501 445.044 252.526 473.031C258.552 501.019 263.626 514.539 263.626 514.539L233.815 470.66L235.084 464.73L238.255 466.865L239.524 457.852L237.938 426.543Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3988" d="M281.38 367.801L288.022 346.485L344.75 330.549L323.719 387.048L281.38 367.801Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3990" d="M268.928 317.718L342.259 329.101L287.745 344.001L268.928 317.718Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3992" d="M323.719 286.261L345.857 262.875L369.101 291.435L346.687 326.41L323.719 286.261Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3994" d="M271.972 314.821L320.121 287.71L343.919 326.41L271.972 314.821Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path3996" d="M373.529 290.4C370.94 289.212 429.703 262.047 429.703 262.047C429.703 262.047 437.175 273.844 439.665 289.986C442.156 306.129 442.156 306.543 442.156 306.543L373.529 290.4Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4000" d="M434.684 267.221L463.74 299.92L451.288 307.163L445.753 306.336C445.753 306.336 443.263 289.158 442.986 286.054C442.709 282.95 434.684 267.221 434.684 267.221Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path4004" d="M376.019 293.298L449.627 311.096L457.376 358.074L443.816 356.005L376.019 293.298Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path4006" d="M371.315 292.884L441.049 356.625L392.069 352.072L348.901 328.066L371.315 292.884Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4008" d="M323.165 389.117L282.764 371.112C282.764 371.112 299.921 402.569 313.757 432.164C327.593 461.758 329.807 432.371 329.807 432.371L318.738 412.917L323.165 389.117Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4010" d="M314.31 441.684C322.889 451.618 325.933 447.892 325.933 447.892L337.555 483.282L317.908 551.99L292.449 513.497L314.31 441.684Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path4012" d="M270.865 519.705L287.468 514.532L311.82 445.202L270.865 519.705Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4014" d="M340.322 481.833L328.146 446.237C328.146 446.237 320.675 451.824 328.423 446.237C336.171 440.649 332.574 431.336 332.574 431.336L325.656 390.152L400.648 419.54L340.322 481.833Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4016" d="M323.719 397.189L327.87 423.472L320.675 410.227L323.719 397.189Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path4018" d="M343.366 483.488L366.611 484.937L397.88 426.783L343.366 483.488Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path4022" d="M281.934 228.935L296.6 220.243L325.656 230.59L281.934 228.935Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4024" d="M393.176 354.349L442.156 359.73L417.251 436.924L404.245 417.884L393.176 354.349Z" fill="#FF5821" stroke="black" stroke-width="0.733055"/>
<path id="path4026" d="M350.007 332.619L390.409 353.935L401.201 411.261L350.007 332.619Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4030" d="M347.24 332.619L400.371 416.435L327.039 387.669L347.24 332.619Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
<path id="path4034" d="M233.507 199.754C233.507 199.754 245.406 198.099 242.639 199.547C239.872 200.996 234.891 203.686 230.186 208.032C225.482 212.378 233.507 199.754 233.507 199.754V199.754Z" fill="#FF9B21" stroke="black" stroke-width="0.733055"/>
</g>
<g id="ailes">
<path id="path4040" d="M235.997 351.776C165.962 348.51 128.026 416.558 128.026 416.558C128.026 416.558 133.863 406.215 161.877 394.783C189.89 383.351 213.235 376.274 213.235 376.274C213.235 376.274 216.737 374.096 224.908 375.729C233.079 377.362 214.403 378.995 202.73 401.315C191.058 423.635 187.556 432.89 187.556 432.89L210.317 417.102C210.317 417.102 201.563 424.724 196.894 442.144C192.225 459.565 191.641 459.565 191.641 459.565L208.566 429.079L216.737 412.203L234.829 378.995L235.997 351.776Z" fill="#AAC3BD"/>
<path id="path4857" d="M425.091 248.343C510.3 225.479 481.702 161.786 481.702 161.786L468.862 141.643C468.862 141.643 489.873 141.643 513.218 167.229C536.563 192.816 520.221 231.467 520.221 231.467C520.221 231.467 516.136 247.254 506.798 261.408C497.46 275.562 496.293 277.195 496.293 277.195C496.293 277.195 539.481 286.45 561.075 260.319C582.669 234.189 583.836 233.1 583.836 233.1C583.836 233.1 594.341 246.71 573.914 282.639C553.488 318.569 472.364 324.012 472.364 324.012C472.364 324.012 492.791 321.291 520.805 337.078C548.819 352.865 553.488 356.676 553.488 356.676C553.488 356.676 511.467 344.155 504.463 350.687C497.46 357.22 469.446 381.173 469.446 381.173L447.852 375.185L451.354 363.208L470.03 361.575L458.941 312.036L480.535 301.693L433.261 254.876L425.091 248.343Z" fill="#AAC3BD"/>
</g>
<path id="cercle" d="M330.09 633.397C506.318 633.397 649.179 498.993 649.179 333.198C649.179 167.403 506.318 33 330.09 33C153.861 33 11 167.403 11 333.198C11 498.993 153.861 633.397 330.09 633.397Z" stroke="url(#paint0_linear_0_1)" stroke-width="3.00432"/>
<g id="cristaux">
<path id="path4963" d="M610.789 618.677L616.703 631.463L632.475 626.545L623.603 608.841L610.789 618.677Z" fill="#FFD021" stroke="url(#paint1_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4961" d="M26.7055 50.1932L23.7482 34.4558L51.3494 43.3081L50.3638 68.8812L26.7055 50.1932Z" fill="#FFD021" stroke="url(#paint2_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4959" d="M24.2632 623.595L53.836 607.857L31.1635 647.201L5.53381 644.25L24.2632 623.595Z" fill="#FFD021" stroke="url(#paint3_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4957" d="M167.066 140.049L171.994 155.787L206.496 158.737L200.581 132.181L167.066 140.049Z" fill="#AAC3BD" stroke="url(#paint4_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4955" d="M657.31 106.336L622.809 125.024L613.937 179.121L657.31 141.745V106.336Z" fill="#FF5821" stroke="url(#paint5_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4953" d="M606.846 418.026L613.746 452.451L656.133 462.287L643.319 425.894L606.846 418.026Z" fill="#AAC3BD" stroke="url(#paint6_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4951" d="M3.42993 369.409L19.202 401.868L58.6323 392.032L27.0881 359.574L3.42993 369.409Z" fill="#FF5821" stroke="url(#paint7_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4949" d="M402.294 593.182L398.351 618.755L431.866 654.164L425.952 604.002L402.294 593.182Z" fill="#FF5821" stroke="url(#paint8_linear_0_1)" stroke-width="0.986" stroke-linejoin="round"/>
<path id="path4947" d="M391.791 77.1497L385.107 130.514L437.238 117.173L391.791 77.1497Z" fill="#FFD021" stroke="url(#paint9_linear_0_1)" stroke-width="1.40953" stroke-linejoin="round"/>
</g>
</g>
<defs>
<linearGradient id="paint0_linear_0_1" x1="9.4447" y1="345.183" x2="650.735" y2="345.183" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_0_1" x1="610.278" y1="620.152" x2="632.986" y2="620.152" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_0_1" x1="23.2372" y1="51.6685" x2="51.8605" y2="51.6685" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_0_1" x1="5.02283" y1="627.529" x2="54.347" y2="627.529" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_0_1" x1="166.554" y1="145.459" x2="207.007" y2="145.459" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_0_1" x1="613.426" y1="142.729" x2="657.821" y2="142.729" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_0_1" x1="606.335" y1="440.156" x2="656.644" y2="440.156" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_0_1" x1="2.9189" y1="380.721" x2="59.1432" y2="380.721" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_0_1" x1="397.84" y1="623.673" x2="432.377" y2="623.673" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_0_1" x1="384.414" y1="103.832" x2="437.931" y2="103.832" gradientUnits="userSpaceOnUse">
<stop/>
<stop offset="1" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

    </div>
  );
}
export default App;