import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LeftArrow from '@mui/icons-material/ArrowBackIos';
import RightArrow from '@mui/icons-material/ArrowForwardIos';
import './Corps.css';
import 'react-loading-skeleton/dist/skeleton.css'

import Projetinf from './Projets/Component/Projetinf';
import ProjetGraphique from './ProjetGraphique';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const img=[
  {src:require('./Images/cerf&fille.jpg')},
  {src:require('./Images/filleBalancoire.jpg')},
    { src:require('./Images/femme2.jpg' )}, 
      {src:require('./Images/garfielsPaysSucre.jpg')},
      { src:require('./Images/gibliUniverse.jpg')}, 
      {src:require('./Images/kimono.jpg')}, 
      {src:require('./Images/bob_patrick_influenceursrogné.jpg')},
      {src:require('./Images/fille_contours_fins.jpg')},  
      {src:require('./Images/fille_meditation.jpg')},  
      {src:require('./Images/pandasoleil.jpg')},  
      {src:require('./Images/renard_sous_fondrouge2.jpg')},  
      {src:require('./Images/renardesprit(copie).jpg')},
      {src:require('./Images/sorciereHalloween.jpg')},
      {src:require('./Images/fleurcerisier2.JPG') },

      
  ]
  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            color: 'red',
          },
        },
      },
    },
  });
  
function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function a11yProps(index) {
 

  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function MenuProjet(props) {
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  
  return (
    <Box sx={{ width: '100%' }}>

      <Box sx={{ borderBottom: 1 }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Projets Informatiques" {...a11yProps(0)} />
          <Tab  label="Projets Graphiques" {...a11yProps(1)} />
        </Tabs> 

      </Box>

      


      <TabPanel value={value} index={0}>
        <div className='englobeC'>
          <Projetinf/>
        </div>
      </TabPanel>
     
     
      <TabPanel value={value} index={1}>
        <ProjetGraphique/>
     </TabPanel>
      
    </Box>     
 
  
  );
}



export default MenuProjet