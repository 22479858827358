import { Component } from 'react';
import { unmountComponentAtNode } from 'react-dom';
import '../CSS/Experience.css'
import fond1 from '../../Images/fondClair.jpg';

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }

  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  }
  render() {

    return (<div>
      <div onClick={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
        <div className="front">
          <div className="image-container" style={{ backgroundImage: `url(${fond1})` }}>
            <h1 className="title">Experience Professionnelle</h1>
          </div>
        </div>
        <div className="back" style={{ overflowX: 'scroll',backgroundColor:'#F7D1D5'}} >
          <div class="item-title" style={{marginTop:'20px'}}>Stages Préparatrice en pharmacie x2</div>
          <div class="item-detail">2016-2017 & 2013-2014 : 1 semaine </div>
          <ul>
            <li className='ssClasse'>faire l'inventaire</li>
            <li className='ssClasse'>mettre antivols</li>
            <li className='ssClasse'>mise en rayon</li>
            <li className='ssClasse'>réception des commandes</li>
          </ul>


          <div class="item-title" style={{marginTop:'60px'}}>Stage Décoratrice d'interieur</div>
          <div class="item-detail">2014-2015 : 1 semaine </div>
          <ul>
            <li className='ssClasse'>faire l'inventaire</li>
            <li className='ssClasse'>rénovation meubles</li>
            <li className='ssClasse'>réception des commandes</li>
          </ul>
        </div>
      </div></div>
    );
  }
}
export default Experience
