import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import fond3 from '../../Images/fond2.jpg';

function createData(annee, nom, obtenu, enCours) {
    return { annee,nom, obtenu,enCours };
  }

  const rows = [
    createData('2021-2022','Licence Informatique (L3)',' ' ,'✔️' ),
    createData('2020-2021','Certification Pix','✔️', ' '),
    createData('2017-2018','Bac Scientifique (option ISN)', '✔️', ' '),

  ];
class Diplome extends React.Component{
  constructor(props) {
    super(props);
    this.state = { flipped: false };
    this.flip = this.flip.bind(this);
  }
  
  flip = () => {
    this.setState({ flipped: !this.state.flipped });
  }
    
render(){
return( <div >
    <div onClick={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
      <div className="front" style={{height:'500px', width:'100%'}} >
          <div className="image-container" style={{backgroundImage:`url(${fond3})`}}>
              <h1 className="title">Diplômes</h1>
          </div>
      </div>         
      <div className="back" style={{backgroundColor:'#aee6e7',height:"100%"}}>
      <TableContainer component={Paper}>
          <Table aria-label="simple table" sx={{width:"auto" ,height:'40vh'}}>
            <TableHead>
              <TableRow sx={{'background':'#EAEAEA'}}>
                <TableCell>Année</TableCell>
                <TableCell align="center">Diplôme</TableCell>
                <TableCell align="center">Obtenu</TableCell>
                <TableCell align="center">En Cours</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.annee}
                  </TableCell>
                  <TableCell align="center" style={{fontWeight:'bold'}}>{row.nom}</TableCell>
                  <TableCell align="center">{row.obtenu}</TableCell>
                  <TableCell align="center">{row.enCours}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>     
    </div>
   


</div>)}
}

export default Diplome


