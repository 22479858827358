import '../CSS/Langagemp.css'
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import langPr from './tabl_prog';
import langW from "./tabl_web";
const autre=[
  {
      src: require("../logos/krita.png"),
      titre: 'Krita: Logiciel dessin',

  },
  {
      src: require("../logos/jupyter.png"),
      titre: 'Jupyter',

  },
  
];
const langMp=[
  {
      src: require("../logos/mk.png"),
      titre: 'Markdown',

  },
  {
      src: require("../logos/lattex.png"),
      titre: 'Lattex',

  },
  {
      src: require("../logos/xml.png"),
      titre: 'XML',

  },

];


export default function Langagemp() {
  return (
    <div><h1 className='titreComp'>Compétences</h1>
    <div className='langages'>
    <div><h1>Langages de typo</h1>
    <div className='Cards'>
       {langMp.map((item)=>
        <Card sx={{ maxWidth: 125}}>
      <CardMedia
         component="img"
         alt="green iguana"
         height="140"
         image={item.src}
       />
       <CardContent style={{'background-color': 'white'}}>
         {item.titre}   
       </CardContent>
    
   </Card>)}</div>
    </div>
    <div><h1>Langages Web</h1>
    <div className='Cards' onClick={changementCSS()}>
       {langW.map((item)=>
        <Card sx={{ maxWidth: 125}}>
      <CardMedia
         component="img"
         alt="green iguana"
         height="140"
         image={item.src}
       />
       <CardContent style={{'background-color': 'white'}}>
         {item.titre}   
       </CardContent>
    
   </Card>)}</div></div>
    <div sx={{ margin: 0}}><h1>Langages de Programmation</h1>
    <div className='Cards'>
       {langPr.map((item)=>
        <Card sx={{ maxWidth: 125}}>
      <CardMedia
         component="img"
         alt="green iguana"
         height="140"
         image={item.src}
       />
       <CardContent style={{'background-color': 'white'}}>
         {item.titre}  
       </CardContent>
    
   </Card>)}</div></div>
   <div><h1>Autre</h1>
    <div className='Cards'>
       {autre.map((item)=>
        <Card sx={{ maxWidth: 125}}>
      <CardMedia
         component="img"
         alt="green iguana"
         height="140"
         image={item.src}
       />
       <CardContent style={{'background-color': 'white'}}>
         {item.titre}  
       </CardContent>
    
   </Card>)}</div></div>
    </div></div>
    
   
  )
}

function changementCSS(){
  document.getElementsByClassName('langages');
}
