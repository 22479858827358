import React, { useState } from "react";
import ReactDOM from "react-dom";
import './logo.png';

import Corps from "./Corps";
import PersistentDrawerLeft from "./Nav/Persistentdrawerleft";

function App(param) {
const [mode,nouveauLien]=useState('Accueil');
  return (
    <div>
      <PersistentDrawerLeft nouveauLien={nouveauLien} />
    <div id="page-wrap"></div>
    <Corps mode={mode}/>
 </div>
  );
}
const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
export default App