const langW=[
    {
        src: require("../logos/html.png"),
        titre: 'HTML',
  
    },
    {
        src: require("../logos/CSS.png"),
        titre: 'CSS',
  
    },
    {
        src: require("../logos/js.png"),
        titre: 'Javascript',
  
    },
    {
      src: require("../logos/php2.png"),
      titre: 'PHP',
  
  },
  {
    src: require("../logos/jsx.png"),
    titre: 'JSX (avec le framework: React)',
  
  },
  {
    src: require("../logos/mysql.png"),
    titre: 'MySQL',
  
  },
  
  ];
  export default langW