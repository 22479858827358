import '../CSS/Bienvenuediv.css'
function Bienvenuediv(){
    const description = "Ce site est un Portfolio. Vous trouverez une multitudes d'infos sur Fanny Goupil dont : ses projets ,sa présentation et de quoi la contacter"
    const nav="Bonne navigation !"
    return <div className='Bienvenue'>
        <h1>BIENVENUE</h1>
        <div className="DescriptionSite">{description}</div>
        <div className="DescriptionBonneNav">{nav}</div>

    </div>
}
export default Bienvenuediv