import React, { useEffect, useState } from "react";

import Bienvenuediv from './PageAccueil/Component/Bienvenuediv';
import Img from './PageAccueil/Component/Img';
import Titre from './PageAccueil/Component/Titre';
import TitrePr from './Projets/Component/TitrePr';

import './Corps.css';
import MenuProjet from './MenuProjet';
import Photo from "./Presentation/Component/Photo";
import Apropos from "./Presentation/Component/Apropos";
import Apropost from "./Presentation/Component/Apropost";
import Projetinf from "./Projets/Component/Projetinf";
import Langagemp from "./Competences/Component/Langagemp";
import Presentation from "./Presentation/Component/Presentation";
function Corps(param) {
  const [mode, nouveauLien] = useState('Informatique');
  switch (param.mode) {
    case 'Accueil':
      return (<div >
        <Titre />
        <div className="englobePageAcc">
          <Img/>
          <Bienvenuediv />
        </div>
      </div>)

    case 'Projets':

      return (<div>
        <TitrePr />
        <MenuProjet />



      </div>)


    case 'Présentation':
      return (<div>
        <Presentation />

      </div>)


    case 'Compétences':
      return (<div>
        <Langagemp />
      </div>)

    default:
      return null
  }
}
export default Corps