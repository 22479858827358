import '../CSS/Presentation.css';
import Box from '@mui/material/Box';
import { Component } from 'react';
import fond1 from '../../Images/moi.jpg';

class Apropos extends Component{
       constructor(props) {
              super(props);
              this.state = { flipped: false };
              this.flip = this.flip.bind(this);
            }
          
            flip = () => {
              this.setState({ flipped: !this.state.flipped });
            }
            render(){
   return( <div >
                <div onClick={this.flip} className={"card-container" + (this.state.flipped ? " flipped" : "")}>
                  <div className="front">
                      <div className="image-container"style={{backgroundImage:`url(${fond1})`}}>
                          <h1 className="title">Presentation</h1>
                      </div>
                  </div>         
                  <div className="back" style={{overflowX:'scroll'}} >
                  <p> Je m'appelle Fanny Goupil, je suis étudiante en 3eme année de licence Informatique, et je souhaite devenir developpeuse web et plus précisément développeuse front-end. <br></br>
           Je m'interesse particulièrement au développement web, le traitement d'image,la modélisation 3D et la création d'applications. <br></br>
           Pour approfondir mes connaissances et me former, j'apprend en autodidacte via des sites dont openClassroom. </p> 
                  </div>     
                </div>
               
    
      
    </div>)}
}

export default Apropos